.box {
  background-color: #f4f4f7;
  margin: 20px 0 15px 0;
  border-radius: 4px; /* Add a unit, e.g., pixels */
  padding: 10px; /* Add a unit, e.g., pixels */
}

.chip {
  padding: 6px 16px 6px 16px;
  border-radius: 100px;
  width: 40%;
  font-size: 12px;
  font-weight: 500;
  text-align: center;
}

.status-title {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.status-body {
  margin: 10px 5px 0 5px;
  display: flex;
  flex-direction: column;
}
